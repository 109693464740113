<template>
  <div class="container-fluid">
    <breadcrumb>
    <img  src="static/img/controlframework.svg" class="breadcrumb-image" />
      <breadcrumb-item>
        <router-link to="/controlframeworks">Control Frameworks</router-link>
      </breadcrumb-item>
      <breadcrumb-item v-if="add">
        <router-link :to="{ path: '../edit' }"
          >Control Framework Categories</router-link
        >
      </breadcrumb-item>
      <breadcrumb-item v-if="add == false">
        <router-link :to="{ path: '../../edit' }"
          >Control Framework Cateogries</router-link
        >
      </breadcrumb-item>
      <breadcrumb-item v-if="add" active>
        Add Control Framework Category
      </breadcrumb-item>
      <breadcrumb-item v-if="add == false" active>
        Edit Control Framework Category
      </breadcrumb-item>
    </breadcrumb>
    <form autocomplete="off">
      <card v-if="loaded">
        <div>
          <div class="card-title" style="font-size: 18px; margin-bottom: 20px">
            {{ model.controlFramework }}
          </div>
          <fg-input
            name="name"
            label="Control Framework Category"
            v-validate="modelValidations.name"
            :error="getError('name')"
            v-model="model.name"
          ></fg-input>
          <fg-input label="Description">
            <textarea
              name="Description"
              v-model="model.description"
              class="form-control"
              style="height: 80px"
            ></textarea>
          </fg-input>
          <fg-input
            name="controlFrameworkId"
            type="hidden"
            v-model="model.controlFrameworkId"
          ></fg-input>
          <checkbox v-model="checkboxValue">Active</checkbox>
        </div>
        <div class="card-footer text-right">
          <button
            type="button"
            @click="close"
            class="btn btn-fill btn-danger btn-wd"
          >
            <span class="btn-label">
              <i class="fa fa-times"></i>
            </span>
            Cancel
          </button>
          <button
            type="submit"
            @click.prevent="validate"
            @click="save"
            class="btn btn-fill btn-info btn-wd"
          >
            <span class="btn-label">
              <i class="fa fa-save"></i>
            </span>
            Save
          </button>
        </div>
      </card>
    </form>
  </div>
</template>

<script>
import { Checkbox } from "src/components/index";
import { Breadcrumb, BreadcrumbItem } from "src/components/index";

export default {
  components: {
    Checkbox,
    Breadcrumb,
    BreadcrumbItem,
  },
  data() {
    return {
      checkboxValue: true,
      model: {
        id: 0,
        name: "",
        description: "",
        controlFrameworkId: 0,
        controlFramework: "",
        isActive: true,
      },
      modelValidations: {
        name: {
          required: true,
        },
      },
      loaded: false,
      add: false,
    };
  },
  created() {
    let self = this;
    self.get(self.$route.params.id);
    //self.getControlFramework(this.$route.params.controlFrameworkId)
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      this.$validator.validateAll().then((isValid) => {
        this.$emit("on-submit", this.controlFrameworkCategory, isValid);
      });
    },

    get(id) {
      let self = this;
      if (id == undefined) {
        self.$store.state.services.controlFrameworkService
          .get(self.$route.params.controlFrameworkId)
          .then((r) => {
            self.model.controlFramework = r.data.name;
            self.model.controlFrameworkId =
              self.$route.params.controlFrameworkId;
            self.add = true;
            self.loaded = true;
          });
        return;
      }
      self.$store.state.services.controlFrameworkCategoryService
        .get(id)
        .then((r) => {
          self.model.id = r.data.id;
          self.model.name = r.data.name;
          self.model.controlFrameworkId = r.data.controlFrameworkId;
          self.model.controlFramework = r.data.controlFramework;
          self.model.description = r.data.description;
          var myValue = r.data.isActive == "True";
          self.model.isActive = myValue;
          self.checkboxValue = myValue;
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },
    save() {
      let self = this;
      self.$validator.validateAll().then((valid) => {
        if (valid) {
          if (self.model.id > 0) {
            self.model.isActive = self.checkboxValue.toString();
            self.$store.state.services.controlFrameworkCategoryService
              .update(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          } else {
            self.model.isActive = self.checkboxValue.toString();
            self.model.controlFrameworkId = parseInt(
              self.model.controlFrameworkId
            );
            self.$store.state.services.controlFrameworkCategoryService
              .add(self.model)
              .then((r) => {
                self.notifyVue("top", "right", "success");
                self.close();
              })
              .catch((err) => console.log(err));
          }
        }
      });
    },
    close() {
      let self = this;
      self.$router.push(
        "/controlFrameworks/" + self.$route.params.controlFrameworkId + "/edit"
      );
    },
    notifyVue(verticalAlign, horizontalAlign, messageType) {
      this.$notify({
        component: {
          template: `<span></br>Control Framework Category saved</br>&nbsp;</span>`,
        },
        icon: "fa fa-check",
        horizontalAlign: horizontalAlign,
        verticalAlign: verticalAlign,
        type: messageType,
      });
    },
  },
};
</script>